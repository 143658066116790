<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">{{title}}</p>
            <p class="title_tip3">FORENSIC CLINICAL IDENTIFICATION</p>
        </div>
        <div class="space_content">
            法医临床鉴定是指鉴定人运用法医临床学的科学技术或者专门知识，对诉讼涉及的与法律有关的人体损伤、残疾、生理功能、病理生理状况及其他相关的医学问题进行鉴别和判断并提供鉴定意见的活动。
        </div>
        <div class="space_content">
法医临床鉴定包括人体损伤程度鉴定，人体残疾等级鉴定，赔偿相关鉴定，人体功能评定，性侵犯与性别鉴定，诈伤、诈病、造作伤鉴定，医疗损害鉴定，骨龄鉴定及与损伤相关的其他法医临床鉴定等。


        </div>
        <img class="img" style="width:100%" v-lazy="require('../../assets/range/1.jpg')">
        <div class="pathology_title">
            人体损伤程度鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            依据相关标准规定的各类致伤因素所致人身损害的等级划分，对损伤伤情的严重程度进行鉴定。
        </div>
        <div class="pathology_title">
            人体残疾等级鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            依据相关标准规定的各类损伤（疾病）后遗人体组织器官结构破坏或者功能障碍所对应的等级划分，对后遗症的严重程度及其相关的劳动能力等事项进行鉴定。
        </div>
        <div class="pathology_title">
            赔偿相关鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            依据相关标准或者法医临床学的一般原则，对人体损伤、残疾有关的赔偿事项进行鉴定。包括医疗终结时间鉴定，人身损害休息（误工）期、护理期、营养期的鉴定，定残后护理依赖、医疗依赖、营养依赖的鉴定，后续诊疗项目的鉴定，诊疗合理性和相关性的鉴定。
        </div>
        <div class="pathology_title">
            人体功能评定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            依据相关标准，在活体检查与实验室检验的基础上，必要时结合伤（病）情资料，对视觉功能、听觉功能、男性性功能与生育功能、嗅觉功能及前庭平衡功能进行综合评定。
        </div>
        <div class="pathology_title">
            性侵犯与性别鉴定


        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            采用法医临床学及临床医学相关学科的理论与技术，对强奸、猥亵、性虐待等非法性侵犯和反常性行为所涉专门性问题进行鉴定，以及对性别（第二性征）进行鉴定。


        </div>
        <div class="pathology_title">
            诈伤、诈病、造作伤鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            采用法医临床学的理论与技术，对诈称（夸大）损伤、诈称（夸大）疾病以及人为造成的身体损伤进行鉴定。


        </div>
        <div class="pathology_title">
            医疗损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            应用法医临床学与临床医学相关学科的理论与技术，对医疗机构实施的诊疗行为有无过错、诊疗行为与患者损害后果之间是否存在因果关系及其原因力大小的鉴定，还包括对医疗机构是否尽到了说明义务、取得患者或者患者近亲属书面同意义务的鉴定（不涉及病理诊断或死亡原因鉴定）
        </div>
        <div class="pathology_title">
            医疗损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            对涉及病理诊断和/或死亡后果等情形的医疗纠纷案件进行鉴定。判断诊疗行为有无过错；诊疗行为与死者死亡后果之间是否存在因果关系以及过错原因力大小等。
        </div>
        <div class="pathology_title">
            骨龄鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            通过个体骨骼的放射影像学特征对青少年的骨骼年龄进行推断。
        </div>
        <div class="pathology_title">
            与人体损伤相关的其他法医临床鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            采用法医临床学及其相关自然科学学科的理论与技术，对人体损伤（疾病）所涉及的除上述以外其他专门性问题的鉴定。包括损伤判定、损伤时间推断、成伤机制分析与致伤物推断、影像资料的同一性认定，以及各种致伤因素造成的人身损害与疾病之间因果关系和原因力大小的鉴定等。
        </div>
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
import mixin from "./mixin.js"
export default {
  mixins:[mixin],
}
</script>
